.sidebar {
  .is-active {
    background-color: #359ae1;
    font-weight: 500;
    color: #fff !important;
  }

  .sub-link-adminstrator-active {
    font-weight: 700 !important;
  }
  .sub-menu-collapse {
    .sub-menu {
      background-color: #ecf7fe;
      transition: height 0.3s ease 0s;
      padding-left: 20px !important;
      z-index: 10;
      a.is__active {
        font-weight: 500;
      }
    }
  }

  .nav-item {
    .nav-link {
      cursor: pointer;
      transition: all 0.2s ease 0s;
    }

    &:hover {
      background-color: #eaecf4;
    }
  }
}

.aside {
  min-height: calc(100vh - 94px);
  min-width: 200px;
  max-width: 300px;
  width: 15%;
  box-shadow: 2px 0px 6px 0px #00000026;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  transition: width 0.3s ease-out;
  position: fixed;
  z-index: 20;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "Roboto";
  .nav-link {
    &:hover {
      color: #3f6ad8 !important;
      background: #e0f3ff;
      font-weight: 500;
    }
    @media (max-width: 800px) {
      .chevron {
        margin-top: -20px !important;
      }
    }
  }

  .fa-chevron-right {
    display: block;
  }

  .fa-chevron-down {
    display: none;
  }

  .is-open {
    .fa-chevron-right {
      display: none;
    }

    .fa-chevron-down {
      display: block;
    }
    .aside__menu-sub {
      display: block;
    }
  }

  &.toggled {
    transition: width 0.3s ease-out;
    width: 6.5rem;
    min-width: 6.5rem;
  }
  .aside__menu {
    width: 100%;
    overflow-y: auto;
    height: 700px;
  }
  .aside__menu-sub {
    display: none;
    background-color: #ecf7fe;
    transition: width 0.2s;
    li {
      list-style: none;
      margin: 0;
      cursor: pointer;
      &:first-child {
        padding-top: 5px;
      }
      a {
        display: block;
        font-weight: 400;
        padding: 8.5px 15px;
        font-size: 14px;
        color: #5f5f5f;
        transition: 0.2s;
        &:hover {
          text-decoration: none;
          color: #3f6ad8;
          background: #e0f3ff;
          font-weight: 500;
        }
      }
    }
    .sub-link-adminstrator-active {
      font-weight: 700 !important;
    }
  }
  .copyright {
    padding: 0 15px 20px;
    p {
      font-size: 13px;
      color: #383838;
      font-weight: 300;
      margin-top: 12px;
    }
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 10em;
}

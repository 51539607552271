.wrapper {
  height: 100vh;
  padding-top: 100px;
}
.title {
  padding: 40px;
}
.module-box {
  cursor: pointer;
  a {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
}
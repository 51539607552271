.container-buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 10px;
  right: 10px;

  .active {
    background-color: #bf0707 !important;
  }

  // .disable{
  //   pointer-events: none;
  // }

  .tool-button {
    margin-bottom: 10px;
    background-color: #666666;
    width: 30px;
    border: 0;
    width: 30px;
    margin: 5px;
    padding-bottom: 4px;

    img {
      width: 18px;
      height: 18px;
    }
  }
}

.opacity {
  position: absolute;
  top: 52%;
  right: 3vw;
  width: 560%;
  &_header {
    display: flex;
    justify-content: end;
    .button-close {
      background: none;
      border: none;
      cursor: pointer;
      margin-top: -10px;
      margin-bottom: 10px;
    }
  }
  &-content {
    span {
      font-size: 13px;
    }
  }
  #ranger-input-current-layer {
    margin-top: 15px;
  }
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}
